<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <section class="section section-pb48">
          <div class="services_faq services_faq-with-banners">
            <div class="services_faq-content">
              <h2>Вопросы и ответы</h2>
              <div class="services_faqs-list">
                <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
                  <a
                    href="#"
                    class="question"
                    :class="{ active: isActive === index }"
                    @click.prevent="setActive(index)"
                  >
                    <div class="title">{{ faq.title }}</div>
                    <ArrowDownIcon class="arrow" />
                  </a>
                  <transition name="fade">
                    <div v-show="isActive === index" class="answer">
                      <EditorJSComponent
                        :text="JSON.parse(faq.answer)"
                        v-if="isJson(faq.answer) && JSON.parse(faq.answer)"
                      />
                    </div>
                  </transition>
                </div>
              </div>
            </div>
            <Banners />
          </div>
        </section>
      </div>
    </div>
  </main>
</template>
<script>
import ArrowDownIcon from "@/components/svg/ArrowDown.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import Banners from "@/views/services/components/Banners.vue";
export default {
  name: "FAQ",
  async asyncData({ store }) {
    await store.dispatch("GET_FAQ_PAGE");
  },
  components: {
    ArrowDownIcon,
    EditorJSComponent,
    Banners,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.state.faq_page = [];
    next();
  },
  computed: {
    faqs() {
      return this.$store.state.faq_page;
    },
  },
  data() {
    return {
      isActive: null,
    };
  },
  methods: {
    setActive(index) {
      if (this.isActive === index) {
        this.isActive = null;
      } else {
        this.isActive = index;
      }
    },
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/services/index.styl"
</style>
